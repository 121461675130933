import "bootstrap/dist/css/bootstrap.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";

import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import "./App.css";
import {AppContext} from "./libs/contextLib";
import DashboardPage from "./pages/DashboardPage";
import DashboardLayoutRoute from "./pages/layouts/DashboardLayout";
import LoginLayoutRoute from "./pages/layouts/LoginLayout";
import LoginPage from "./pages/LoginPage";
import CalendarPage from "./pages/CalendarPage";

function App() {
  const isAuthenticated = JSON.parse(
    localStorage.getItem("isAuthenticated") as string
  );



  return (
    <AppContext.Provider value={{ isAuthenticated }}>
      <Router>
        <Switch>
          <Route exact path="/">
            <DashboardLayoutRoute path="/" component={CalendarPage} />
          </Route>
          <Route exact path="/login">
            {isAuthenticated ? (
              <Redirect to="/admin" />
            ) : (
              <LoginLayoutRoute path="/login" component={LoginPage} />
            )}
          </Route>
          <Route exact path="/admin">
            {!isAuthenticated ? (
              <Redirect to="/login" />
            ) : (
              <DashboardLayoutRoute path="/admin" component={DashboardPage} />
            )}
          </Route>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
