import moment from "moment";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import React, {useCallback, useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import axios from "../libs/axios";

const DataTableDenni: React.FC = () => {
   const formPrazdnaRezervace = {
      id: null,
      name: "",
      surname: "",
      email: "",
      phone: "",
      message: "",
      datumForm: "",
      timeForm: "",
      uniqID: "",
      casDialog: "",
      cas: "",
      datum: "",
   };

   const [displayBasic, setDisplayBasic] = useState(false);
   const [confirmDialog, setConfirmDialog] = useState(false);
   const [deleteDialog, setDeleteDialog] = useState(false);
   const [products, setProducts] = useState([]);
   const [loading, setLoading] = useState(true);
   const [formData, setFormData] = useState(formPrazdnaRezervace);
   const [formDatum, setFormDatum] = useState("");
   const [confirmDeleteData, setConfirmDeleteData] =
      useState(formPrazdnaRezervace);
   const [datumDialog, setDatumDialog] = useState("");
   const [casDialog, setCasDialog] = useState("");
   const [formCas, setFormCas] = useState("");
   const [idDialog, setIdDialog] = useState("");
   const [formName, setFormName] = useState("");
   const [formSurname, setFormSurname] = useState("");
   const [formEmail, setFormEmail] = useState("");
   const [formPhone, setFormPhone] = useState("");
   const [formMessage, setFormMessage] = useState("");
   const [submitted, setSubmitted] = useState(false);
   const [potvrzovaciHlaska, setPotvrzovaciHlaska] = useState(false);

   const openDeleteDialog = useCallback((row) => {
      setDeleteDialog(true);
      setConfirmDeleteData(row);
      setIdDialog(row.id);
   }, []);

   const openConfirmDialog = useCallback(() => {
      setConfirmDialog(true);
   }, []);

   const onHideDeleteDialog = () => {
      setDeleteDialog(false);
   };

   const onDeleteSubmit = () => {
      setDeleteDialog(false);
      axios
         .post("server/api/deleteevent", {
            id: idDialog,
         })
         .then((response) => {
            axios
               .get(
                  "server/api/event-list-week"
               )
               .then((responses: any) => {
                  setProducts(responses.data.data);
               });

            openConfirmDialog();
         });
   };

   const onSubmit = () => {
      setSubmitted(true);

      if (
         formData.name.trim() &&
         formData.datum.trim() &&
         formData.cas.trim() &&
         formData.surname.trim() &&
         formData.email.trim() &&
         formData.phone.trim() &&
         formData.datumForm.trim()
      ) {
         formData.datumForm = moment(formData.datum).format("Y-MM-DD");
         formData.timeForm = formData.cas;
         axios
            .post("server/api/saveevent", formData)
            .then((response) => {
               axios.get(
                  "server/api/event-list-week"
               )
                  .then((responses: any) => {
                     setProducts(responses.data.data);
                  });
            });
         setDisplayBasic(false);
         setPotvrzovaciHlaska(true);
      }
   };

   useEffect(() => {
      axios.get("server/api/event-list-week")
         .then((responses: any) => {
            setProducts(responses.data.data);
            setLoading(false);
         });
   }, []);

   const onHide = () => {
      setDisplayBasic(false);
   };
   const onHideHlaska = () => {
      setPotvrzovaciHlaska(false);
   };

   const renderFooter = (
      <div>
         <Button label="Uložiy změny" icon="pi pi-check" onClick={onSubmit}/>
         <Button label="Zrušit" icon="pi pi-times" onClick={onHide}/>
      </div>
   );
   const deleteDialogFooter = (
      <div>
         <Button
            label="Ano smazat"
            icon="pi pi-check"
            className="p-button-danger"
            onClick={onDeleteSubmit}
         />
         <Button label="Zrušit" icon="pi pi-times" onClick={onHideDeleteDialog}/>
      </div>
   );

   const potvrzovaciHlaskaFooter = (
      <div>
         <Button label="Zavřít" icon="pi pi-times" onClick={onHideHlaska}/>
      </div>
   );

   const datumBody = useCallback((row) => {
      return <strong>{moment(row.datum).format("dddd DD.MM.Y")}</strong>;
   }, []);

   const naplnPole = useCallback(
      (nazev: string, value: any) => {
         let pole: any = formData;
         pole[`${nazev}`] = value;
         setFormData(pole);
         setFormName(formData.name);
         setFormDatum(formData.datum);
         setFormCas(formData.cas);
         setFormSurname(formData.surname);
         setFormEmail(formData.email);
         setFormPhone(formData.phone);
         setFormMessage(formData.message);
      },
      [formData]
   );
   const openDialog = useCallback(
      (row) => {
         let pole: any = formData;
         pole.name = row.name;
         pole.cas = row.cas;
         pole.datum = row.datum;
         pole.surname = row.surname;
         pole.email = row.email;
         pole.phone = row.phone;
         pole.message = row.message;
         pole.id = row.id;

         setFormData(pole);
         setDatumDialog(row.datum);
         setCasDialog(row.cas);
         setFormCas(row.cas);
         setFormDatum(row.datum);
         setIdDialog(row.id);
         setFormName(row.name);
         setFormSurname(row.surname);
         setFormEmail(row.email);
         setFormMessage(row.message);
         setDisplayBasic(true);
      },
      [datumDialog]
   );
   const actionBodyTemplate = useCallback((row) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-success p-mr-2"
               onClick={() => openDialog(row)}
            />
            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-warning"
               onClick={() => openDeleteDialog(row)}
            />
         </React.Fragment>
      );
   }, []);

   return (
      <div>
         <div className="card">
            <DataTable
               value={products}
               paginator
               rows={5}
               rowsPerPageOptions={[5, 10, 20]}
            >
               <Column field="datum" header="Datum" body={datumBody}/>
               <Column field="cas" header="Čas"/>
               <Column field="name" header="Jméno"/>
               <Column field="surname" header="Příjmení"/>
               <Column field="jmenoDitete" header="Dítě"/>
               <Column field="email" header="E-mail"/>
               <Column field="phone" header="Telefon"/>
               <Column body={actionBodyTemplate}/>
            </DataTable>
         </div>
         <Dialog
            header="Rezervační formulář"
            visible={displayBasic}
            style={{width: "75vw"}}
            footer={renderFooter}
            onHide={() => onHide()}
            onShow={() => naplnPole("datumForm", `${datumDialog}`)}
         >
            <p>
               <strong>Rezervace na datum:</strong>{" "}
               {moment(datumDialog).format("dddd DD.MM.Y")} <br/>
               <strong>čas:{casDialog}</strong>
            </p>

            <InputText
               id="formDatum"
               hidden
               placeholder=""
               value={`${moment(datumDialog).format("Y-MM-DD")}`}
            />
            <InputText id="formId" hidden placeholder="" value={`${setIdDialog}`}/>
            <div className="p-field">
               <div className="p-field p-col-12  pt-4">
            <span className="p-float-label w-100 p-input-icon-right">
              <i className="pi pi-user"/>
              <InputText
                 id="formDatum"
                 required
                 className={
                    submitted && formDatum === "" ? "p-invalid w-100" : "w-100"
                 }
                 placeholder=""
                 value={formDatum}
                 onChange={(e): void =>
                    naplnPole("datum", e.currentTarget.value)
                 }
              />
              <label htmlFor="formDatum">čas:</label>
            </span>

                  {submitted && formDatum === "" && (
                     <small className="p-invalid">Toto je povinné pole.</small>
                  )}
               </div>
            </div>
            <div className="p-field">
               <div className="p-field p-col-12  pt-4">
            <span className="p-float-label w-100 p-input-icon-right">
              <i className="pi pi-user"/>
              <InputText
                 id="formCas"
                 required
                 className={
                    submitted && formCas === "" ? "p-invalid w-100" : "w-100"
                 }
                 placeholder=""
                 value={formCas}
                 onChange={(e): void => naplnPole("cas", e.currentTarget.value)}
              />
              <label htmlFor="formCas">čas:</label>
            </span>

                  {submitted && casDialog === "" && (
                     <small className="p-invalid">Toto je povinné pole.</small>
                  )}
               </div>
            </div>

            <div className="p-field">
               <div className="p-field p-col-12  pt-4">
            <span className="p-float-label w-100 p-input-icon-right">
              <i className="pi pi-user"/>
              <InputText
                 id="jmeno"
                 required
                 className={
                    submitted && formName === "" ? "p-invalid w-100" : "w-100"
                 }
                 placeholder=""
                 value={formName}
                 onChange={(e): void => naplnPole("name", e.currentTarget.value)}
              />
              <label htmlFor="jmeno">Jméno:</label>
            </span>

                  {submitted && formName === "" && (
                     <small className="p-invalid">Toto je povinné pole.</small>
                  )}
               </div>
            </div>

            <div className="p-field w-100  pt-4">
          <span className="p-float-label w-100 p-input-icon-right">
            <i className="pi pi-user"/>
            <InputText
               id="prijmeni"
               value={formSurname}
               className={
                  submitted && formSurname === "" ? "p-invalid w-100" : "w-100"
               }
               onChange={(e): void =>
                  naplnPole("surname", e.currentTarget.value)
               }
            />
            <label htmlFor="prijmeni">Příjmení:</label>
          </span>

               {submitted && formSurname === "" && (
                  <small className="p-invalid">Toto je povinné pole.</small>
               )}
            </div>

            <div className="p-field w-100  pt-4">
          <span className="p-float-label w-100 p-input-icon-right">
            <i className="pi pi-envelope"/>
            <InputText
               className={
                  submitted && formEmail === "" ? "p-invalid w-100" : "w-100"
               }
               id="email"
               value={formEmail}
               onChange={(e): void => naplnPole("email", e.currentTarget.value)}
            />
            <label htmlFor="email">E-mail:</label>
          </span>
               {submitted && formEmail === "" && (
                  <small className="p-invalid">Toto je povinné pole.</small>
               )}
            </div>

            <div className="p-field w-100  pt-4">
          <span className="p-float-label w-100 p-input-icon-right">
            <i className="pi pi-mobile"/>
            <InputText
               className={
                  submitted && formPhone === "" ? "p-invalid w-100" : "w-100"
               }
               id="phone"
               value={formPhone}
               onChange={(e): void => naplnPole("phone", e.currentTarget.value)}
            />
            <label htmlFor="phone">Telefon:</label>
          </span>
               {submitted && formPhone === "" && (
                  <small className="p-invalid">Toto je povinné pole.</small>
               )}
            </div>
            <div className="p-field w-100  pt-4">
          <span className="p-float-label w-100 p-input-icon-right">
            <i className="pi pi-bookmark"/>
            <InputTextarea
               className="w-100"
               id="message"
               rows={5}
               cols={30}
               value={formMessage}
               onChange={(e): void =>
                  naplnPole("message", e.currentTarget.value)
               }
            />
            <label htmlFor="phone">Poznámka:</label>
          </span>
            </div>
         </Dialog>
         <Dialog
            visible={potvrzovaciHlaska}
            style={{width: "550px"}}
            header="Registrace odeslána"
            modal
            footer={potvrzovaciHlaskaFooter}
            onHide={onHideHlaska}
         >
            <div className="confirmation-content">
               <i
                  className="pi pi-info-circle p-mr-3 p-3"
                  style={{fontSize: "2rem", color: "green"}}
               />

               <span>Upraveno</span>
            </div>
         </Dialog>
         <Dialog
            visible={deleteDialog}
            style={{width: "450px"}}
            header="Smazat záznam?"
            modal
            footer={deleteDialogFooter}
            onHide={onHideDeleteDialog}
         >
            <div className="confirmation-content">
               <p>
                  <i
                     className="pi pi-exclamation-triangle p-mr-3"
                     style={{fontSize: "1rem", color: "red"}}
                  />
                  &nbsp; <strong>opravdu smazat tento záznam?</strong> <br/>
                  {confirmDeleteData.datum} {confirmDeleteData.cas},
                  {confirmDeleteData.name} {confirmDeleteData.surname}{" "}
               </p>
            </div>
         </Dialog>
      </div>
   );
};
export default DataTableDenni;
