import { Col, Row } from "react-bootstrap";
import React, { FC, useState } from "react";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { InputTextarea } from "primereact/inputtextarea";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { IForm } from "../CalendarPage";

const FormValidateSchema = Yup.object({
  name: Yup.string().required("Toto pole je povinnné"),
  surname: Yup.string().required("Toto pole je povinnné"),
  childrenForm: Yup.string().required("Toto pole je povinnné"),
  phone: Yup.string().required("Toto pole je povinnné"),
  datumNarozeni: Yup.string().required("Toto pole je povinnné"),
  datumForm: Yup.string().required("Toto pole je povinnné"),
  email: Yup.string()
    .required("Toto pole je povinnné")
    .email("E-mail není ve správném tvaru"),
});

interface IProps {
  datumDialog: string;
  minutaDialog: string;
  hourDialog: string;
  onHide: () => void;
  onFinish: (values: IForm) => void;
}

const FormComponent: FC<IProps> = (props) => {
  const { datumDialog, hourDialog, minutaDialog, onHide, onFinish } = props;

  const [cities, setCities] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<IForm>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    datumNarozeni: "",
    datumForm: `${moment(datumDialog).format("Y-MM-DD")}`,
    childrenForm: "",
    message: "",
    cities: [],
    cas: `${hourDialog}:${minutaDialog}`,
    timeForm: `${hourDialog}:${minutaDialog}`,
  });

  const onCityChange = (e: any) => {
    let selectedCities = [...cities];
    if (e.checked) {
      // @ts-ignore
      selectedCities.push(e.value);
    }
    // @ts-ignore
    else selectedCities.splice(selectedCities.indexOf(e.value), 1);

    setCities(selectedCities);
    naplnPole("cities", selectedCities);
  };

  const naplnPole = (name: string, value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="d-flex flex-column w-100">
      <Formik
        initialValues={formValues}
        validationSchema={FormValidateSchema}
        onSubmit={(values, { setSubmitting }) => {
          onFinish(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <InputText id="formDatum" hidden placeholder="" />
            <div className="p-field">
              <div className="p-field p-col-12  pt-4">
                <InputText
                  id="datumForm"
                  required
                  hidden
                  disabled
                  className={
                    isSubmitting && values.datumForm === ""
                      ? "p-invalid w-100"
                      : "w-100"
                  }
                  placeholder=""
                  onChange={handleChange}
                />
                {errors.datumForm && touched.datumForm && errors.datumForm}
              </div>
            </div>
            <div className="p-field">
              <div className="p-field p-col-12  pt-4">
                <InputText
                  id="cas"
                  required
                  className={
                    isSubmitting && values.cas === ""
                      ? "p-invalid w-100"
                      : "w-100"
                  }
                  disabled
                  hidden
                  placeholder=""
                  onChange={handleChange}
                />

                {errors.timeForm && touched.timeForm && errors.timeForm}
                <InputText
                  id="timeForm"
                  required
                  className={
                    isSubmitting && values.timeForm === ""
                      ? "p-invalid w-100"
                      : "w-100"
                  }
                  disabled
                  hidden
                  placeholder=""
                  onChange={handleChange}
                />

                {errors.timeForm && touched.timeForm && errors.timeForm}
              </div>
            </div>
            <Row>
              <Col>
                <div className="p-field">
                  <div className="p-field p-col-6  pt-4">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <i className="pi pi-user" />
                      <InputText
                        id="name"
                        required
                        className={
                          isSubmitting && values.name === ""
                            ? "p-invalid w-100"
                            : "w-100"
                        }
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label htmlFor="formCas">Jméno:</label>
                    </span>

                    {errors.name && touched.name && errors.name}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="p-field">
                  <div className="p-field p-col-6  pt-4">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <i className="pi pi-user" />
                      <InputText
                        id="surname"
                        required
                        className={
                          isSubmitting && values.surname === ""
                            ? "p-invalid w-100"
                            : "w-100"
                        }
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label htmlFor="formCas">Příjmení:</label>
                    </span>

                    {errors.surname && touched.surname && errors.surname}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="p-field">
                  <div className="p-field p-col-6  pt-4">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <i className="pi pi-user" />
                      <InputText
                        id="childrenForm"
                        required
                        className={
                          isSubmitting && values.childrenForm === ""
                            ? "p-invalid w-100"
                            : "w-100"
                        }
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label htmlFor="formCas">Jméno a přijme ní dítěte:</label>
                    </span>

                    {errors.childrenForm &&
                      touched.childrenForm &&
                      errors.childrenForm}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="p-field">
                  <div className="p-field p-col-6  pt-4">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <i className="pi pi-calendar" />
                      <InputText
                        id="datumNarozeni"
                        required
                        className={
                          isSubmitting && values.datumNarozeni === ""
                            ? "p-invalid w-100"
                            : "w-100"
                        }
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label htmlFor="datumNarozeni">
                        Datum narození dítěte:
                      </label>
                    </span>

                    {errors.childrenForm &&
                      touched.childrenForm &&
                      errors.childrenForm}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="p-field">
                  <div className="p-field p-col-6  pt-4">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <InputText
                        id="email"
                        required
                        className={
                          isSubmitting && values.email === ""
                            ? "p-invalid w-100"
                            : "w-100"
                        }
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label htmlFor="email">E-mail:</label>
                    </span>

                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="p-field">
                  <div className="p-field p-col-6  pt-4">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <i className="pi pi-phone" />
                      <InputText
                        id="phone"
                        required
                        className={
                          isSubmitting && values.phone === ""
                            ? "p-invalid w-100"
                            : "w-100"
                        }
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label htmlFor="phone">Telefon:</label>
                    </span>

                    {errors.phone && touched.phone && errors.phone}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col>
                <div className="p-field">
                  <div className="p-field p-col  pt-4">
                    <span className=" w-100 ">
                      <Field
                        type="checkbox"
                        id="type1"
                        name="cities"
                        value="Očkování"
                      />
                      <label htmlFor="type1" className="p-checkbox-label">
                        Očkování
                      </label>
                    </span>
                  </div>
                </div>
              </Col>

              <Col>
                <div className="p-field">
                  <div className="p-field p-col  pt-4">
                    <span className=" w-100 ">
                      <Field
                        type="checkbox"
                        id="type2"
                        value="Prevence"
                        name="cities"
                      />
                      <label htmlFor="type2" className="p-checkbox-label">
                        Prevence
                      </label>
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="p-field">
                  <div className="p-field p-col  pt-4">
                    <span className=" w-100 ">
                      <Field
                        type="checkbox"
                        id="type3"
                        value="Nemoc"
                        name="cities"
                      />
                      <label htmlFor="type3" className="p-checkbox-label">
                        Nemoc
                      </label>
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="p-field">
                  <div className="p-field p-col  pt-4">
                    <span className=" w-100 ">
                      <Field
                        type="checkbox"
                        id="type4"
                        value="vyplnění žádosti/formuláře"
                        name="cities"
                      />
                      <label htmlFor="type4" className="p-checkbox-label">
                        vyplnění žádosti/formuláře
                      </label>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="p-field">
                  <div className="p-field p-col  pt-5">
                    <span className="p-float-label w-100 p-input-icon-right">
                      <InputTextarea
                        itemID="message"
                        id="message"
                        className={
                          "p-inputtextarea p-inputtext p-component w-100"
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="message" className="p-checkbox-label">
                        Důvod návštěvy
                      </label>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-between w-100">
                <Button
                  loading={isSubmitting}
                  className=" p-button-success"
                  label="Odeslat rezervaci"
                  icon="pi pi-check"
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => handleSubmit}
                />
                <Button label="Zrušit" icon="pi pi-times" onClick={onHide} />
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default FormComponent;
