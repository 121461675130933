import React, { Component } from "react";
import { Route } from "react-router-dom";

interface Props {
  name: string;
}
export const DashboardLayout: React.FC = (children) => {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-3 login-signin-on d-flex flex-row-fluid"
        id="kt_login"
      >
        <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid">
          <div className="login-form text-center text-white p-7 position-relative overflow-hidden">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardLayoutRoute = ({ ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>

          <Component {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
