import axios from "axios";

// import { store } from "../redux/store/store";
//
// const user = store.getState().rootReducer.ZakladniData.data;



export default axios.create({
   baseURL: "https://rezervace.detsky-doktor-albahri.cz/",
});
