import { Button } from "primereact/button";
import React, { useCallback } from "react";
import DataTableDenni from "../componets/DataTableDenni";
import DataTableWeek from "../componets/DataTableTydenni";
const DashboardPage: React.FC = () => {
  const logOutFce = useCallback(() => {
    localStorage.setItem("isAuthenticated", String(false));
    window.location.href = "/login";
  }, []);

  return (
    <>
      <div className="d-flex p-2 flex-row-reverse">
        <Button onClick={logOutFce} label="Odhlásit se" />
      </div>

      <div className="login-container">
        <div className="flex-item">
          <h2>Přehledy denní</h2>
          <DataTableDenni />
        </div>
        <div className="flex-item">
          <h2>Přehledy týdení</h2>
          <DataTableWeek />
        </div>
      </div>
    </>
  );
};
export default DashboardPage;
