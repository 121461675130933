import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useCallback, useState } from "react";

const LoginPage: React.FC = () => {
  const formPrazdnaRezervace = {
    formName: "",
    formPassword: "",
  };

  const onSubmit = () => {
    setSubmitted(true);
    if (formData.formName.trim() && formData.formPassword.trim()) {
      if (
        formData.formName === "admin" &&
        formData.formPassword === "WHdKd52jjD78mAdx"
      ) {
        localStorage.setItem("isAuthenticated", String(true));
        window.location.href = "/admin";
        setSubmitted(false);
      }
    }
  };

  const [formData, setFormData] = useState(formPrazdnaRezervace);
  const naplnPole = useCallback(
    (nazev: string, value: any) => {
      let pole: any = formData;
      pole[`${nazev}`] = value;
      setFormData(pole);
      setFormName(pole.formName);
      setFormPassword(pole.formPassword);
    },
    [formData]
  );
  const [submitted, setSubmitted] = useState(false);
  const [formName, setFormName] = useState("");
  const [formPassword, setFormPassword] = useState("");
  return (
    <div className="login-container">
      <div className="flex-item">
        <h2>PŘIHLÁŠENÍ DO SYSTÉMU</h2>

        <div className="p-field">
          <div className="p-field p-col-12  pt-4">
            <span className="p-float-label w-100 p-input-icon-right">
              <i className="pi pi-user" />
              <InputText
                id="username"
                required
                className={
                  submitted && formName === "" ? "p-invalid w-100" : "w-100"
                }
                placeholder="Uživatel"
                value={formName}
                onChange={(e): void =>
                  naplnPole("formName", e.currentTarget.value)
                }
              />
            </span>

            {submitted && formName === "" && (
              <small className="p-invalid">Toto je povinné pole.</small>
            )}
          </div>
        </div>
        <div className="p-field">
          <div className="p-field p-col-12  pt-4">
            <span className="p-float-label w-100 p-input-icon-right">
              <i className="pi pi-lock" />
              <InputText
                id="password"
                placeholder="Heslo"
                required
                type="password"
                className={
                  submitted && formPassword === "" ? "p-invalid w-100" : "w-100"
                }
                value={formPassword}
                onChange={(e): void =>
                  naplnPole("formPassword", e.currentTarget.value)
                }
              />
            </span>

            {submitted && formPassword === "" && (
              <small className="p-invalid">Toto je povinné pole.</small>
            )}
          </div>
        </div>
        <div className="p-field">
          <div className="p-field p-col-12  pt-4">
            <Button label={"Přihlásit"} onClick={onSubmit} />,
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
